import SocialLinkIcon from "./SocialLinkIcon";
import Link from "next/link";

export default function AppFooter() {
  return (
    <footer className="text-light">
      <div className="container">
        <div className="row g-custom-x">
          <div className="col-lg-3">
            <div className="widget">
              <h5>About MT EVEREST LIMO LLC</h5>
              <p>
                At MT EVEREST LIMO LLC, we believe that every journey should be
                an unforgettable experience. Our commitment to excellence,
                luxury, and reliability sets us apart as a premier provider of
                fleet booking services.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="widget">
              <h5>Contact Info</h5>
              <address className="s1">
                <span>
                  <i className="id-color fa fa-map-marker fa-lg"></i>
                  {/* 1300 Palmer Ave San pablo Ca, 94806 */}
                  2433 Shattuck Ave., Berkeley, CA 94704
                </span>
                <span>
                  <a href="tel:+14153092068">
                    <i className="id-color fa fa-phone fa-lg"></i>+14153092068
                  </a>
                </span>
                <span>
                  <i className="id-color fa fa-envelope-o fa-lg"></i>
                  <a href="mailto:contact@example.com">
                    contact@mteverestlimo.com
                  </a>
                </span>
              </address>
            </div>
          </div>

          <div className="col-lg-2 col-md-6">
            <h5>Quick Links</h5>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="widget">
                  <ul>
                    <li>
                      <Link href={"/about-us"}>About Us</Link>
                    </li>
                    <li>
                      <Link href={"/fleets"}>Our Fleet</Link>
                    </li>
                    <li>
                      <Link href={"/reservations"}>Reservations</Link>
                    </li>
                    <li>
                      <Link href={"/service-rates"}> Service Rates</Link>
                    </li>
                    <li>
                      <Link href={"/services"}>Our Services</Link>
                    </li>
                    <li>
                      <Link href={"/contact"}>Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="widget">
              <h5>Social Network</h5>
              <SocialLinkIcon />
            </div>
          </div>
        </div>
      </div>
      <div className="subfooter">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="de-flex">
                <div className="de-flex-col">
                  <a href="/">Copyright 2025 - MT EVEREST LIMO LLC</a>
                </div>
                <ul className="menu-simple">
                  {/* <li>
                    <a href="/terms-condition">Terms &amp; Conditions</a>
                  </li> */}
                  <li>
                    <Link href="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>|</li>
                  <div className="powered-by">
                    {/* <h4>Powered By :</h4>
                    <li className="powered-by-class">
                      <Link href="https://namanize.com/">namanize.com</Link>
                    </li>

                    <li>
                      <Link href="https://tisheart.com/">tisheart.com</Link>
                    </li> */}
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
