import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request from "@/utils/request.utils";
import {
  RejectValue,
  RejectValueAsyncThunk,
  Reservation,
  ResponseErrorType,
  SuccessResponseType,
} from "@/types/model.types";
import { createDefaultObject } from "@/utils/object.partial.utils";
import { RootState } from "..";
import { PAGE_SIZE } from "@/utils/constants.utils";

type ReservationState = {
  model: Reservation | null;
  data: Reservation[];
  status: "saving" | "fetching" | "idle" | "deleting" | "loading";
  dialogue: boolean;
  errors?: ResponseErrorType | null;
  record: Reservation | null;
};

const initialState: ReservationState = {
  status: "idle",
  dialogue: false,
  record: null,
  model: createDefaultObject<Reservation>() as Reservation,
  data: [],
  errors: null,
};

const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    reservationInputChange(state, { payload }) {
      let { key, value }: { key: keyof Reservation; value: any } = payload;
      (state.model as Partial<Reservation>)[key] = value;
    },
    returnInputChange(state, { payload }) {
      let { key, value }: { key: keyof Reservation; value: any } = payload;
      (state.model as Partial<Reservation>)[key] = value;
    },
    updateReservationState(state, { payload }) {
      const { key, value }: { key: keyof ReservationState; value: any } =
        payload;
      (state as Record<keyof ReservationState, any>)[key] = value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitReservation.pending, (state) => {
      state.status = "saving";
    });
    builder.addCase(submitReservation.fulfilled, (state) => {
      state.status = "idle";
    });
    builder.addCase(submitReservation.rejected, (state, action) => {
      state.status = "idle";
      state.errors = action.payload?.data;
    });
    builder.addCase(verifyReservation.rejected, (state, action) => {
      state.errors = action.payload?.data;
    });
  },
});

export const submitReservation = createAsyncThunk<
  SuccessResponseType<any>,
  number,
  { rejectValue: { data: ResponseErrorType } }
>(
  "reservation/submit",
  async (totalPayableAmount, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        reservation: { model },
      } = getState() as RootState;
      const response = await request.post("/reservations", {
        ...model,
        charged_amount: totalPayableAmount,
      });
      let stops = model.stops;
      let returnstops = model.returnstops;
      // console.log("checking response" + response.data);

      dispatch(
        updateReservationState({
          key: "model",
          value: response.data.reservation,
        }),
      );
      dispatch(
        reservationInputChange({
          key: "stops",
          value: stops,
        }),
      );
      dispatch(
        reservationInputChange({
          key: "returnstops",
          value: returnstops,
        }),
      );

      return response;
    } catch (error: any) {
      return rejectWithValue(error.response as { data: ResponseErrorType });
    }
  },
);
export const editReservation = createAsyncThunk<
  SuccessResponseType<any>,
  number,
  { rejectValue: { data: ResponseErrorType } }
>(
  "reservation/edit",
  async (totalPayableAmount, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        reservation: { model },
      } = getState() as RootState;
      const response = await request.patch(`/reservation/${model.id}`, {
        ...model,
        charged_amount: totalPayableAmount,
      });
      let stops = model.stops;
      let returnstops = model.returnstops;
      console.log("checking response" + response.data.message);
      debugger;
      dispatch(
        updateReservationState({
          key: "model",
          value: response.data.reservation,
        }),
      );
      dispatch(
        reservationInputChange({
          key: "stops",
          value: stops,
        }),
      );
      dispatch(
        reservationInputChange({
          key: "returnstops",
          value: returnstops,
        }),
      );
      dispatch(
        reservationInputChange({
          key: "amtDifference",
          value: response.data.amtDifference,
        }),
      );

      return response;
    } catch (error: any) {
      return rejectWithValue(error.response as { data: ResponseErrorType });
    }
  },
);

export const updateReservation = createAsyncThunk<
  SuccessResponseType<any>,
  null,
  { rejectValue: { data: ResponseErrorType } }
>("reservation/update", async (_, { rejectWithValue, getState }) => {
  try {
    const {
      reservation: { model },
    } = getState() as RootState;
    return await request.patch(`/reservations/${model.id}`, { ...model });
  } catch (error: any) {
    return rejectWithValue(error.response as { data: ResponseErrorType });
  }
});

export const fetchReservations = createAsyncThunk<
  { data: SuccessResponseType<Reservation[]> },
  { pageNum: number; withLimit?: number } | null,
  RejectValueAsyncThunk
>("reservation/fetch", async (params, { rejectWithValue, dispatch }) => {
  try {
    const pageNum = params?.pageNum || 1;
    const limit = params?.withLimit || PAGE_SIZE;

    dispatch(updateReservationState({ key: "status", value: "fetching" }));
    const response = await request.get("/reservations", {
      params: { page: pageNum, pageSize: limit, withLimit: limit },
    });
    dispatch(updateReservationState({ key: "data", value: response.data }));
    return response;
  } catch (error: any) {
    return rejectWithValue(error.response as RejectValue);
  } finally {
    dispatch(updateReservationState({ key: "status", value: "idle" }));
  }
});

export const verifyReservation = createAsyncThunk<
  SuccessResponseType<Reservation>,
  { payment_intent: string; payment_intent_client_secret: string },
  RejectValueAsyncThunk
>(
  "reservation/verify",
  async (paymentVerificationPayload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(updateReservationState({ key: "status", value: "loading" }));
      const response = await request.post("/reservations/verify", {
        ...paymentVerificationPayload,
      });
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response as RejectValue);
    } finally {
      dispatch(updateReservationState({ key: "status", value: "idle" }));
    }
  },
);
export const verifyTempReservation = createAsyncThunk<
  SuccessResponseType<Reservation>,
  { payment_intent: string; payment_intent_client_secret: string },
  RejectValueAsyncThunk
>(
  "reservation/tempverify",
  async (paymentVerificationPayload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(updateReservationState({ key: "status", value: "loading" }));
      const response = await request.post("/tempreservations/verify", {
        ...paymentVerificationPayload,
      });
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response as RejectValue);
    } finally {
      dispatch(updateReservationState({ key: "status", value: "idle" }));
    }
  },
);

export const completeReservation = createAsyncThunk<
  SuccessResponseType<Reservation>,
  { code: string; link: string },
  RejectValueAsyncThunk
>(
  "reservation/verify",
  async (reservationPayload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(updateReservationState({ key: "status", value: "completing" }));
      const response = await request.post("/reservations/complete", {
        ...reservationPayload,
      });
      dispatch(
        updateReservationState({ key: "model", value: response.data.data }),
      );
      dispatch(fetchReservations(null));
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response as RejectValue);
    } finally {
      dispatch(updateReservationState({ key: "status", value: "idle" }));
    }
  },
);

export const refundReservation = createAsyncThunk<
  SuccessResponseType<any>,
  string,
  RejectValueAsyncThunk
>("reservation/refund", async (code, { rejectWithValue, dispatch }) => {
  try {
    dispatch(updateReservationState({ key: "status", value: "deleting" }));
    const response = await request.post("/reservations/refund", {
      code: code,
    });
    dispatch(
      updateReservationState({ key: "model", value: response.data.data }),
    );
    dispatch(fetchReservations(null));
    return response;
  } catch (error: any) {
    return rejectWithValue(error.response as RejectValue);
  } finally {
    dispatch(updateReservationState({ key: "status", value: "idle" }));
  }
});

export const refundSingleReservation = createAsyncThunk<
  SuccessResponseType<any>,
  string,
  RejectValueAsyncThunk
>("reservation/refundsingle", async (code, { rejectWithValue, dispatch }) => {
  try {
    dispatch(updateReservationState({ key: "status", value: "deleting" }));
    const response = await request.post("/reservations/refund", {
      code: code,
    });
    dispatch(
      updateReservationState({ key: "model", value: response.data.data }),
    );

    return response;
  } catch (error: any) {
    return rejectWithValue(error.response as RejectValue);
  } finally {
    dispatch(updateReservationState({ key: "status", value: "idle" }));
  }
});

export const cancelReservation = createAsyncThunk<
  SuccessResponseType<Reservation>,
  { code: string; link: string },
  RejectValueAsyncThunk
>(
  "reservation/cancel",
  async (reservationPayload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(updateReservationState({ key: "status", value: "cancelling" }));
      const response = await request.post("/reservations/cancel", {
        ...reservationPayload,
      });
      dispatch(
        updateReservationState({ key: "model", value: response.data.data }),
      );
      dispatch(fetchReservations(null));
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response as RejectValue);
    } finally {
      dispatch(updateReservationState({ key: "status", value: "idle" }));
      dispatch(updateReservationState({ key: "dialogue", value: false }));
    }
  },
);

export const fetchReservation = createAsyncThunk<
  { data: SuccessResponseType<Reservation> },
  { code: string } | null,
  RejectValueAsyncThunk
>(
  "customer/reservations/fetch",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const code = params?.code;

      dispatch(updateReservationState({ key: "status", value: "fetching" }));
      const response = await request.get(`/customer/reservations/${code}`, {
        params: {},
      });
      dispatch(updateReservationState({ key: "data", value: response.data }));
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response as RejectValue);
    } finally {
      dispatch(updateReservationState({ key: "status", value: "idle" }));
    }
  },
);

export const fetchReservationToChange = createAsyncThunk<
  { data: SuccessResponseType<Reservation> },
  { code: string } | null,
  RejectValueAsyncThunk
>(
  "customer/reservations/fetch",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const code = params?.code;

      dispatch(updateReservationState({ key: "status", value: "fetching" }));
      const response = await request.get(`/customer/reservations/${code}`, {
        params: {},
      });
      dispatch(updateReservationState({ key: "model", value: response.data }));
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response as RejectValue);
    } finally {
      dispatch(updateReservationState({ key: "status", value: "idle" }));
    }
  },
);

export const cancelCustomerReservation = createAsyncThunk<
  SuccessResponseType<Reservation>,
  { code: string },
  RejectValueAsyncThunk
>(
  "customer/reservation/cancel",
  async (reservationPayload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(updateReservationState({ key: "status", value: "cancelling" }));
      const response = await request.post("/customer/reservation/cancel", {
        ...reservationPayload,
      });
      dispatch(
        updateReservationState({ key: "model", value: response.data.data }),
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response as RejectValue);
    } finally {
      dispatch(updateReservationState({ key: "status", value: "idle" }));
    }
  },
);

export const {
  updateReservationState,
  reservationInputChange,
  returnInputChange,
} = serviceSlice.actions;

export default serviceSlice.reducer;
