"use client";

import React, { useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { Drawer } from "antd";
import { NAV_ITEMS } from "@/utils/constants.utils";
import { Category, Service } from "@/types/model.types";
import SocialLinkIcon from "./SocialLinkIcon";
import { usePathname } from "next/navigation";
import ReservationSearchModal from "./ReservationSearchModal";
type AppHeaderProps = {
  categories?: Category[];
  services?: Service[];
};
export default function AppHeader({ categories }: AppHeaderProps) {
  const params = usePathname();
  const [navDrawer, setNavDrawer] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onOrderClick = (e: any) => {
    e.preventDefault();
    setIsOpen(true);
  };

  return (
    <header className="transparent header-light scroll-light has-topbar">
      <div id="topbar" className="topbar-dark text-light">
        <div className="container">
          <div className="topbar-left xs-hide">
            <div className="topbar-widget">
              <div className="topbar-widget">
                <a href="tel:+14153092068">
                  <i className="fa fa-phone"></i>+14153092068
                </a>
              </div>
              <div className="topbar-widget">
                <a href="mailto:contact@mteverestlimo.com">
                  <i className="fa fa-envelope"></i>
                  contact@mteverestlimo.com
                </a>
              </div>
              <div className="topbar-widget">
                <a href="#">
                  <i className="fa fa-clock-o"></i>24 hours service
                </a>
              </div>
            </div>
          </div>

          <div className="topbar-right">
            <SocialLinkIcon />
          </div>
          <div className="clearfix"></div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="de-flex">
              <div className="de-flex-col">
                <div className="de-flex-col">
                  <div id="logo">
                    <Link
                      href="/"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent default navigation
                        window.location.href = "/"; // Force page reload
                      }}
                    >
                      <Image
                        width={72}
                        height={72}
                        src="/images/logo/logo.png"
                        className="limo-logo"
                        // style={{ height: "60px", width: "auto" }}
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="de-flex-col head  er-col-mid">
                <a
                  href="#"
                  onClick={() => {
                    setNavDrawer(true);
                  }}
                  className="small-device-nav-trigger"
                >
                  <i className="fa fa-bars fa-lg"></i>
                </a>
                <ul id="mainmenu" className="mainmenu-nav">
                  <li>
                    <Link
                      href="/"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent default navigation
                        window.location.href = "/"; // Force page reload
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link href={"/about-us"}>About Us</Link>
                  </li>
                  <li>
                    <Link href={"/fleets"}>Our Fleets</Link>
                    <ul>
                      {categories?.map((category) => {
                        return (
                          <li key={category.id}>
                            <Link
                              href={{
                                pathname: "/fleets",
                                query: {
                                  cat: category.id,
                                },
                              }}
                            >
                              {category.title}
                            </Link>
                            {category.fleets.length > 0 && (
                              <ul>
                                {category.fleets.map((fleet) => {
                                  return (
                                    <li key={`${fleet.title}-${fleet.id}`}>
                                      <Link
                                        href={{
                                          pathname: "/fleets/[slug]",
                                          query: { slug: fleet.slug },
                                        }}
                                      >
                                        {fleet.title}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                  <li>
                    {/* <Link href={"/service-rates"}>Service Rates</Link> */}
                  </li>
                  {/* <li>
                    <Link href={"/services"}>Our Services</Link>
                    <ul>
                      {services?.map((service) => (
                        <li key={service.title}>
                          <Link
                            href={{
                              pathname: "/services/[slug]",
                              query: { slug: service.slug },
                            }}
                          >
                            {" "}
                            {service.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li> */}
                  <li>
                    <Link href={"/view-reservation"}>View Reservation</Link>
                  </li>
                  <li>
                    <Link href={"/contact"}>Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title="Navigation"
        placement="right"
        onClose={() => {
          setNavDrawer(false);
        }}
        open={navDrawer}
      >
        <ul className="drawer-navigation">
          {NAV_ITEMS.map((item) => {
            return (
              <li key={item.path}>
                <Link
                  onClick={() => {
                    setNavDrawer(false);
                  }}
                  className={
                    params.includes(item.path) && item.path != "/"
                      ? "active"
                      : ""
                  }
                  href={item.path}
                >
                  {" "}
                  <i className={item.icon}></i> &nbsp;&nbsp;{item.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </Drawer>

      <ReservationSearchModal
        dialogue={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </header>
  );
}
