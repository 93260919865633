import Link from "next/link";

export default function SocialLinkIcon() {
  return (
    <div className="social-icons">
      <Link href="https://www.facebook.com/MtEverestLimo/" target="_blank">
        <i className="fa fa-facebook fa-lg"></i>
      </Link>
 


      <Link href="https://www.instagram.com/mt_everest_limo/" target="_blank">
        <i className="fa fa-instagram fa-lg pr-0"></i>
      </Link>
  
    </div>
  );
}
