"use client";

import React from "react";
import { Modal, Button, Form, Input } from "antd";
import { fetchReservation } from "@/store/slices/reservation";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/store";
import { useRouter } from "next/navigation";

interface ReservationSearchModalProps {
  dialogue: boolean;
  onClose: () => void;
}

const ReservationSearchModal = ({
  dialogue,
  onClose,
}: ReservationSearchModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const router = useRouter();

  const [form] = Form.useForm();

  const handleSearch = () => {
    form
      .validateFields()
      .then((values) => {
        const reservationCode = values.reservationCode;
        searchOrder(reservationCode);
        form.resetFields();
      })
      .catch((info) => {
        console.error("Validation Failed:", info);
      });
  };

  const searchOrder = async (reservationCode: string) => {
    const data = await dispatch(fetchReservation({ code: reservationCode }));
    if (
      data.payload &&
      "status" in data.payload &&
      data.payload.status === 200
    ) {
      router.push("/view-reservation");
      form.resetFields();
      onClose();
    } else {
      form.setFields([
        {
          name: "reservationCode",
          errors: ["Reservation not found. Please try again."],
        },
      ]);
    }
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title="Search Reservation"
      open={dialogue}
      onCancel={() => onCancel()}
      footer={[
        <Button key="cancel" onClick={() => onCancel()}>
          Cancel
        </Button>,
        <Button key="search" type="primary" onClick={handleSearch}>
          Search
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="reservationCode"
          rules={[
            { required: true, message: "Please enter your reservation code!" },
          ]}
        >
          <Input placeholder="Enter reservation code" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReservationSearchModal;
