import { ActionPayload, TotalStopType } from "@/types/index.types";
import { DefaultLocation, Fleet, Reservation } from "@/types/model.types";
import { PAGE_SIZE } from "./constants.utils";
import { debug } from "console";
export const getChargeType = (chargerType: Fleet["charger_type"]) => {
  const chargerTypes = {
    0: "Charger not available",
    1: "Both IOS / Android",
    2: "Ios",
    3: "Android",
  };
  return chargerTypes[chargerType];
};

export const getStatus = (status: Reservation["status"]) => {
  if (!status) return "";
  const reservationStatus = {
    complete: "badge rounded-pill bg-success",
    pending: "badge rounded-pill  bg-primary",
    paid: "badge rounded-pill bg-default",
    refunded: "badge rounded-pill bg-warning",
    cancelled: "badge rounded-pill bg-danger",
    "refund-requested": "badge rounded-pill bg-info",
  };
  return reservationStatus[status];
};

export const setCookie = (name: string, value: string, days: number) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
};

export const eraseCookie = (name: string) => {
  document.cookie = name + "=; Max-Age=-99999999;";
};

export const getCookie = (name: string) => {
  const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${name}=`));

  if (cookieValue) {
    return cookieValue.split("=")[1];
  }

  return null;
};

export const getListType = (listtype: DefaultLocation["list_type"]) => {
  if (!listtype) return "";

  const listType = {
    pickup: "Pick Up",
    dropoff: "Drop off",
  };

  return listType[listtype];
};

export const getDefaultLocationServiceType = (
  defaultLocation: DefaultLocation["service_type"],
) => {
  if (!defaultLocation) return "";
  if (defaultLocation === "distance") {
    return "Distance";
  } else if (defaultLocation === "hourly") {
    return "Hourly";
  } else {
    return "Airport Transfer";
  }
};

export const constructQuery = <T,>(payload?: ActionPayload & T) => {
  return {
    ...payload,
    page: payload?.page || 1,
    pageSize: payload?.pageSize || PAGE_SIZE,
  };
};

export const getTotalPayablePrice = ({
  totalDistanceInMeter,
  totalReturnDistanceInMeter = 0,
  fleet,
  pickupdate,
  greet_meet = false,

  serviceType,
  hours = null,
  transferType = "one-way",
  totalStops = [],
  returntotalStops = [],
  childSeats,
  boosterSeat,
}: {
  totalDistanceInMeter: number;
  totalReturnDistanceInMeter?: number;
  fleet: Fleet;
  promodate?: string[] | undefined;
  pickupdate: string;
  serviceType: string | String;
  hours?: number | null;
  transferType?: string;
  totalStops?: TotalStopType[];
  returntotalStops?: TotalStopType[];
  childSeats: number;
  boosterSeat: number;
  greet_meet?: boolean;
}) => {
  let inMiles: string | number = totalDistanceInMeter / 1609.34;
  // console.log("total distance", totalDistanceInMeter);
  // console.log("total return distance", totalReturnDistanceInMeter);
  let inReturnMiles: string | number = totalReturnDistanceInMeter / 1609.34;

  inMiles = inMiles.toFixed(2);
  inReturnMiles = inReturnMiles.toFixed(2);

  if (!fleet) return [inMiles, 0, 0, 0];

  //Get the base price for the vechile for distance service
  let distBasePrice = fleet.fleet_rate?.flat_rate
    ? fleet.fleet_rate.flat_rate
    : 0;

  if (serviceType === "airport-transfer") {
    if (
      pickupdate &&
      fleet.fleet_rate?.promo_date &&
      fleet.fleet_rate?.promo_airport_transfer &&
      fleet.fleet_rate?.promo_date?.includes(pickupdate.split(" ")[0]) &&
      Number(fleet.fleet_rate?.promo_airport_transfer) !== 0
    ) {
      distBasePrice = fleet.fleet_rate?.promo_airport_transfer!;
    } else {
      distBasePrice = fleet.fleet_rate?.airport_transfer!;
    }

    // fleet.fleet_rate?.per_mile! * (parseFloat(inMiles) - 10);
    // console.log("pareko hisab pachi" + totalAmount);
  }

  // const pricePerMile = fleet.fleet_rate?.per_mile
  //   ? fleet.fleet_rate?.per_mile * parseFloat(inMiles)
  //   : null;

  // const basePrice =
  //   pricePerMile! > fleet.fleet_rate?.flat_rate!
  //     ? null
  //     : fleet.fleet_rate?.flat_rate! - pricePerMile!;

  let totalAmount = 0;
  //If base price is given totalAmount will be the base price first
  totalAmount += distBasePrice;

  //Get the no of miles included in base price

  const includedMiles: number = fleet.fleet_rate?.per_mile
    ? distBasePrice / fleet.fleet_rate?.per_mile
    : 0;

  //Remaining miles that need to be charged according to the given per mile price
  const milesToBecharged: number =
    parseFloat(inMiles) - includedMiles > 0
      ? parseFloat(inMiles) - includedMiles
      : 0;
  //Remaining miles that need to be charged according to the given per mile price for return distance
  const returnmilesToBeCharged: number =
    parseFloat(inReturnMiles) - includedMiles > 0
      ? parseFloat(inReturnMiles) - includedMiles
      : 0;

  //If distance type transfer is return then we will double the base price
  if (transferType === "return") {
    totalAmount = Number(totalAmount) * 2;
  }

  //calculate and add remaining price for distance and airport transfer
  totalAmount = fleet.fleet_rate?.per_mile
    ? Number(totalAmount) +
      Number(milesToBecharged) * fleet.fleet_rate?.per_mile
    : 0;

  if (transferType === "return") {
    totalAmount = fleet.fleet_rate?.per_mile
      ? Number(totalAmount) +
        Number(returnmilesToBeCharged) * fleet.fleet_rate?.per_mile
      : 0;
  }

  //If type is return total miles that need to be shown in ui is
  if (transferType === "return") {
    inMiles = Number(inMiles) + Number(inReturnMiles);
  }

  if (serviceType === "hourly" && hours) {
    if (
      pickupdate &&
      fleet.fleet_rate?.promo_date &&
      fleet?.fleet_rate?.promo_per_hour &&
      fleet.fleet_rate?.promo_date?.includes(pickupdate.split(" ")[0]) &&
      Number(fleet?.fleet_rate?.promo_per_hour) !== 0 //If the promo rate is given in the promo date
    ) {
      totalAmount = fleet.fleet_rate?.promo_per_hour! * hours;
    } else {
      totalAmount = fleet.fleet_rate?.per_hour! * hours;
    }
  }

  // if (
  //   fleet.fleet_rate?.waiting_time &&
  //   parseInt(fleet.fleet_rate?.waiting_time || "0", 10) > 0 &&
  //   serviceType != "hourly"
  // ) {
  //   let amount = totalStops
  //     .filter((stopInfo) => parseInt(stopInfo.waiting_time || "0", 10) > 1)
  //     .map((stopInfo) => {
  //       return (
  //         (parseInt(stopInfo.waiting_time!, 10) *
  //           60 *
  //           parseInt(fleet.fleet_rate?.waiting_time || "0", 10)) /
  //         100
  //       );
  //     });
  //   let returnamount = returntotalStops
  //     .filter((stopInfo) => parseInt(stopInfo.waiting_time || "0", 10) > 1)
  //     .map((stopInfo) => {
  //       return (
  //         (parseInt(stopInfo.waiting_time!, 10) *
  //           60 *
  //           parseInt(fleet.fleet_rate?.waiting_time || "0", 10)) /
  //         100
  //       );
  //     });
  //   // totalAmount =
  //   //   totalAmount * 1 +
  //   //   amount.reduce((a, b) => a + b, 0) +
  //   //   returnamount.reduce((a, b) => a + b, 0);
  // }

  if (childSeats) {
    totalAmount = Number(totalAmount) + 20 * Number(childSeats);
    if (transferType === "return") {
      totalAmount = Number(totalAmount) + 20 * Number(childSeats);
    }
  }

  if (boosterSeat) {
    totalAmount = Number(totalAmount) + 10 * Number(boosterSeat);
    if (transferType === "return") {
      totalAmount = Number(totalAmount) + 10 * Number(boosterSeat);
    }
  }

  if (returntotalStops?.length > 0) {
    totalAmount = Number(totalAmount) + returntotalStops.length * 10;
  }
  if (totalStops?.length > 0) {
    totalAmount = Number(totalAmount) + totalStops.length * 10;
  }

  if (serviceType === "airport-transfer") {
    if (greet_meet) {
      totalAmount = Number(totalAmount) + 50;
    }
  }
  // console.log("final with seats " + totalAmount);

  return [inMiles, totalAmount];
};

export const getTotalReturnPayablePrice = ({
  totalReturnDistanceInMeter = 0,
  fleet,
  pickupdate,
  greet_meet = false,
  transferType,
  serviceType,
  hours = null,

  totalStops = [],

  childSeats,
  boosterSeat,
}: {
  totalReturnDistanceInMeter?: number;
  fleet: Fleet;
  promodate?: string[] | undefined;
  pickupdate: string;
  serviceType: string | String;
  hours?: number | null;
  transferType?: string;
  totalStops?: TotalStopType[];
  returntotalStops?: TotalStopType[];
  childSeats: number;
  boosterSeat: number;
  greet_meet?: boolean;
}) => {
  let inMiles: string | number = totalReturnDistanceInMeter / 1609.34;
  // console.log("total distance", totalDistanceInMeter);
  // console.log("total return distance", totalReturnDistanceInMeter);

  inMiles = inMiles.toFixed(2);

  if (!fleet) return [inMiles, 0, 0, 0];
  if (transferType === "one-way") return [0, 0, 0, 0];

  //Get the base price for the vechile for distance service
  let distBasePrice = fleet.fleet_rate?.flat_rate
    ? fleet.fleet_rate.flat_rate
    : 0;

  if (serviceType === "airport-transfer") {
    if (
      pickupdate &&
      fleet.fleet_rate?.promo_date &&
      fleet.fleet_rate?.promo_airport_transfer &&
      fleet.fleet_rate?.promo_date?.includes(pickupdate.split(" ")[0]) &&
      Number(fleet.fleet_rate?.promo_airport_transfer) !== 0
    ) {
      distBasePrice = fleet.fleet_rate?.promo_airport_transfer!;
    } else {
      distBasePrice = fleet.fleet_rate?.airport_transfer!;
    }

    // fleet.fleet_rate?.per_mile! * (parseFloat(inMiles) - 10);
    // console.log("pareko hisab pachi" + totalAmount);
  }

  // const pricePerMile = fleet.fleet_rate?.per_mile
  //   ? fleet.fleet_rate?.per_mile * parseFloat(inMiles)
  //   : null;

  // const basePrice =
  //   pricePerMile! > fleet.fleet_rate?.flat_rate!
  //     ? null
  //     : fleet.fleet_rate?.flat_rate! - pricePerMile!;

  let totalAmount = 0;
  //If base price is given totalAmount will be the base price first
  totalAmount += distBasePrice;

  //Get the no of miles included in base price

  const includedMiles: number = fleet.fleet_rate?.per_mile
    ? distBasePrice / fleet.fleet_rate?.per_mile
    : 0;

  //Remaining miles that need to be charged according to the given per mile price
  const milesToBecharged: number =
    parseFloat(inMiles) - includedMiles > 0
      ? parseFloat(inMiles) - includedMiles
      : 0;
  //Remaining miles that need to be charged according to the given per mile price for return distance

  //If distance type transfer is return then we will double the base price

  //calculate and add remaining price for distance and airport transfer
  totalAmount = fleet.fleet_rate?.per_mile
    ? Number(totalAmount) +
      Number(milesToBecharged) * fleet.fleet_rate?.per_mile
    : 0;

  //If type is return total miles that need to be shown in ui is

  if (serviceType === "hourly" && hours) {
    if (
      pickupdate &&
      fleet.fleet_rate?.promo_date &&
      fleet?.fleet_rate?.promo_per_hour &&
      fleet.fleet_rate?.promo_date?.includes(pickupdate.split(" ")[0]) &&
      Number(fleet?.fleet_rate?.promo_per_hour) !== 0 //If the promo rate is given in the promo date
    ) {
      totalAmount = fleet.fleet_rate?.promo_per_hour! * hours;
    } else {
      totalAmount = fleet.fleet_rate?.per_hour! * hours;
    }
  }

  // if (
  //   fleet.fleet_rate?.waiting_time &&
  //   parseInt(fleet.fleet_rate?.waiting_time || "0", 10) > 0 &&
  //   serviceType != "hourly"
  // ) {
  //   let amount = totalStops
  //     .filter((stopInfo) => parseInt(stopInfo.waiting_time || "0", 10) > 1)
  //     .map((stopInfo) => {
  //       return (
  //         (parseInt(stopInfo.waiting_time!, 10) *
  //           60 *
  //           parseInt(fleet.fleet_rate?.waiting_time || "0", 10)) /
  //         100
  //       );
  //     });
  //   let returnamount = returntotalStops
  //     .filter((stopInfo) => parseInt(stopInfo.waiting_time || "0", 10) > 1)
  //     .map((stopInfo) => {
  //       return (
  //         (parseInt(stopInfo.waiting_time!, 10) *
  //           60 *
  //           parseInt(fleet.fleet_rate?.waiting_time || "0", 10)) /
  //         100
  //       );
  //     });
  //   // totalAmount =
  //   //   totalAmount * 1 +
  //   //   amount.reduce((a, b) => a + b, 0) +
  //   //   returnamount.reduce((a, b) => a + b, 0);
  // }

  if (childSeats) {
    totalAmount = Number(totalAmount) + 20 * Number(childSeats);
  }

  if (boosterSeat) {
    totalAmount = Number(totalAmount) + 10 * Number(boosterSeat);
  }

  if (totalStops?.length > 0) {
    totalAmount = Number(totalAmount) + totalStops.length * 10;
  }

  if (serviceType === "airport-transfer") {
    if (greet_meet) {
      totalAmount = Number(totalAmount) + 50;
    }
  }
  // console.log("final with seats " + totalAmount);

  return [inMiles, totalAmount];
};
