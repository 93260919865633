import { eraseCookie } from "./helpers.utils";

export const DASHBOARD_NAV_ITEMS = [
  // {
  //   name: "Dashboard",
  //   icon: "fa fa-home",
  //   path: "/dashboard",
  // },
  {
    name: "Reservations",
    icon: "fa-solid fa-bookmark",
    path: "/dashboard/reservations",
  },
  {
    name: "Categories",
    icon: "fa fa-boxes-stacked",
    path: "/dashboard/categories",
  },
  {
    name: "Fleets",
    icon: "fa fa-car-side",
    path: "/dashboard/fleets",
  },
  // {
  //   name: "Services",
  //   icon: "fa fa-hand-holding-hand",
  //   path: "/dashboard/services",
  // },
  {
    name: "Services",
    icon: "fa fa-hand-holding-hand",
    path: "/dashboard/services",
  },
  {
    name: "Rates",
    icon: "fa fa-money-bill",
    path: "/dashboard/rates",
  },
  {
    name: "Manage Admins",
    icon: "fa fa-user-secret",
    path: "/dashboard/admins",
  },
  {
    name: "Messages",
    icon: "fa fa-user-secret",
    path: "/dashboard/messages",
  },
  {
    name: "Profile",
    icon: "fa fa-user",
    path: "/dashboard/profile",
  },
  {
    name: "Default Location",
    icon: "fa fa-map-marker",
    path: "/dashboard/default-location",
  },
  {
    name: "Log Out",
    icon: "fa fa-sign-out",
    path: "/login",
    action: () => {
      eraseCookie("_mt_jt_at");
      window.location.href = "/login";
    },
  },
];

export const NAV_ITEMS = [
  {
    name: "Home",
    icon: "fa fa-home",
    path: "/",
  },
  {
    name: "About Us",
    icon: "fa fa-regular fa-address-card",
    path: "/about-us",
  },
  {
    name: "Our Fleet",
    icon: "fa fa-car-side",
    path: "/fleets",
  },
  {
    name: "View Reservation",
    icon: "fa fa-bookmark",
    path: "/view-reservation",
  },
  // {
  //   name: "Service Rates",
  //   icon: "fa fa-wallet",
  //   path: "/service-rates",
  // },
  {
    name: "Our Services",
    icon: "fa fa-bell-concierge",
    path: "/services",
  },
  {
    name: "Contact",
    icon: "fa fa-message",
    path: "/contact",
  },
];

export const PAGE_SIZE = 10000;
